/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */

import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import {jsx, Styled} from 'theme-ui'
import {Layout, SEO, Contact, ContentCarousel, Promotion} from '../components'
import {ListItem} from '../components/Listing'
import {Container, PostsWrapper} from '../elements'
import Navigation from '../components/navigation'
import {langPrefix} from '../utils/lang-prefix'

const DaliaSite = ({
  data: {
    DaliaPugatsch,
    prismicForm,
    allEvents,
    DaliaPugatsch: {lang},
    i18nPathname,
  },
  location,
}) => (
  <Layout>
    <Navigation customNav lang={lang} i18nPathname={i18nPathname.nodes} />
    <SEO data={DaliaPugatsch.data} pathname={location.pathname} />
    <div
      sx={{
        minHeight: ['100vh', '', '60vh'],
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: `center`,
        maxWidth: '1200px',
        margin: '0 auto',
        mt: ['0', '', '4rem'],
        mb: ['6rem', '', '4rem'],
        pl: '1.5rem',
        pr: '1.5rem',
      }}
    >
      <div
        sx={{
          flexBasis: 'calc(99.9% * 3 / 5 - 1rem)',
          maxWidth: 'calc(99.9% * 3 / 5 - 1rem)',
          width: 'calc(99.9% * 3 / 5 - 1rem)',
          '@media (max-width: 800px)': {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
          },
        }}
      >
        <h1
          sx={{
            fontSize: ['3rem', '', '4.5rem'],
            lineHeight: 1.1,
            mb: '0.5rem',
          }}
        >
          {DaliaPugatsch.data.title}
        </h1>
        <hr
          sx={{
            backgroundColor: (t) => t.colors.primary,
            height: '4px',
            width: '10rem',
            border: 0,
            margin: '0',
            padding: '0',
          }}
        />
        <h2
          sx={{
            fontSize: ['1.75rem', '', '2rem'],
            lineHeight: 1.3,
            mt: '1rem',
            fontWeight: 500,
          }}
        >
          {DaliaPugatsch.data.untertitel}
        </h2>
      </div>

      {/* Image */}
      <div
        sx={{
          position: 'relative',
          height: '100%',
          img: {
            margin: '0 auto',
            width: '100%',
          },

          flexBasis: 'calc(99.9% * 2 / 5 - 1rem)',
          maxWidth: 'calc(99.9% * 2 / 5 - 1rem)',
          width: 'calc(99.9% * 2 / 5 - 1rem)',
          '@media (max-width: 800px)': {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
          },
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1,
            background:
              'linear-gradient(0deg, rgba(247, 250, 252, 1) 0%, rgba(247, 250, 252, 0) 40%)',
          },
        }}
      >
        <Img
          fluid={
            DaliaPugatsch.data.header_image.localFile.childImageSharp.fluid
          }
        />
      </div>
    </div>
    {/* intro text */}
    <Container>
      <div
        dangerouslySetInnerHTML={{__html: DaliaPugatsch.data.intro_text.html}}
      />
    </Container>

    {/* New sections - dynamic data */}
    <Container type="big">
      {DaliaPugatsch.data.body.map((items) => (
        <ContentCarousel
          title={items.primary.title1}
          content={items.primary.text_content}
          imgs={items.items}
          captions
          capDesc={items.items}
        />
      ))}
    </Container>

    {/* Book info */}
    <Container type="article">
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Styled.h1>{DaliaPugatsch.data.sec2_title}</Styled.h1>
        <div
          sx={{
            mt: '1rem',
            mb: '2rem',
            ml: 'auto',
            mr: 'auto',
            width: ['100%', '', '31.25rem'],
            div: {
              boxShadow: '8px 24px 30px rgba(0, 0, 0, 0.12)',
              borderRadius: '0 1rem 1rem 0',
            },
            img: {},
          }}
        >
          <Link
            to={langPrefix(
              DaliaPugatsch.data.bookBtnLink.uid,
              DaliaPugatsch.lang
            )}
            sx={{
              '> div': {
                width: '30rem',
                maxWidth: '60vw',
                m: '0 auto',
              },
            }}
          >
            <Img
              fluid={
                DaliaPugatsch.data.sec2_image.localFile.childImageSharp.fluid
              }
            />
          </Link>
        </div>
        <Styled.p>{DaliaPugatsch.data.sec2_des}</Styled.p>
        <div sx={{mt: '1rem'}}>
          <Link
            to={langPrefix(
              DaliaPugatsch.data.bookBtnLink.uid,
              DaliaPugatsch.lang
            )}
          >
            <button
              type="button"
              sx={{
                variant: 'buttons.primary',
              }}
            >
              {DaliaPugatsch.data.sec2_btn1}
            </button>
          </Link>
        </div>
      </div>
    </Container>

    <Container>
      <Promotion
        title={DaliaPugatsch.data.sec3_desc}
        btn1={DaliaPugatsch.data.sec3_btn1}
        btn2={DaliaPugatsch.data.sec3_btn2}
      />
    </Container>

    {/* If there's events data */}
    {allEvents.nodes.length > 0 && (
      <Container>
        <Styled.h1
          sx={{
            mb: [0, '', '1rem'],
          }}
        >
          Kommende Veranstaltungen
        </Styled.h1>
        <PostsWrapper>
          {allEvents.nodes.map((item) => (
            <ListItem
              img={item.data.image.localFile.childImageSharp.fluid}
              capTitle={item.data.cb_title}
              date={item.data.event_date}
              path={`/veranstaltungen/${item.uid}`}
              isFuture={item.isFuture}
              key={item.data.uid}
            />
          ))}
        </PostsWrapper>
      </Container>
    )}

    <Contact data={prismicForm} lang={DaliaPugatsch.lang} />
  </Layout>
)

export const query = graphql`
  query DaliaPugatsch($lang: String) {
    i18nPathname: allPrismicPersonlicheSeite {
      nodes {
        uid
        lang
      }
    }

    DaliaPugatsch: prismicPersonlicheSeite(lang: {eq: $lang}) {
      uid
      lang
      data {
        title
        untertitel
        header_image {
          localFile {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        intro_text {
          html
        }
        sec2_title
        sec2_des
        sec2_btn1
        sec2_image {
          localFile {
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sec3_desc
        sec3_btn1
        sec3_btn2
        bookBtnLink: book_btn_link {
          id
          url
          uid
          slug
          type
          linkType: link_type
        }
        body {
          ... on PrismicPersonlicheSeiteBodyDiashowInhalt {
            slice_type
            id
            primary {
              title1
              text_content
            }
            items {
              sec1_desc
              image {
                url
                alt
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }

    prismicForm(lang: {eq: $lang}) {
      lang
      ...FormInformation
    }

    allEvents: allPrismicEvents(
      sort: {fields: data___event_date, order: ASC}
      filter: {isFuture: {eq: true}}
      limit: 2
    ) {
      nodes {
        ...EventPost
      }
    }
  }
`

export default DaliaSite
